@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css";

@import "//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.4.1/css/bootstrap-reboot.css";
@import "//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.4.1/css/bootstrap-grid.min.css";

@import "https://cdn.jsdelivr.net/npm/bootstrap-utilities@4.1.3/bootstrap-utilities.css";

html, body, #root {
  height: 100%;
  overflow-y: auto;
}

.scale_-1 {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

.rc-notification {
  top: 15px !important;
}
